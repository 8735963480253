import React, {useRef} from 'react';
import Header from "../../components/partials/Header/Header";
import Hero from "../../components/partials/Hero/Hero";
import s from './home.module.scss'
import Form from "../../components/Form/Form";
import Location from "../../components/partials/Location/Location";
import Advantages from "../../components/partials/Advantages/Advantages";
import Footer from "../../components/partials/Footer/Footer";

type Props = {};

const Home: React.FC<Props> = () => {
    const formRef = useRef<HTMLDivElement>(null);
    const locationRef = useRef<HTMLDivElement>(null);
    const advantagesRef = useRef<HTMLDivElement>(null);

    const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    };

    return <div className={s.homeWrapper}>
        <Header
            scrollAction={scrollToSection}
            locationRef={locationRef}
            advantagesRef={advantagesRef}
        />
        <Hero/>
        <Form sectionRef={formRef}/>
        <Location sectionRef={locationRef}/>
        <Advantages sectionRef={advantagesRef}/>
        <Footer
            formRef={formRef}
        />
    </div>;
};

export default Home;
