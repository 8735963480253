import React from 'react';
import s from './form.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from "notistack";

type Props = {
    sectionRef: React.RefObject<HTMLDivElement>;
};

const Form: React.FC<Props> = ({ sectionRef }) => {
    const { enqueueSnackbar } = useSnackbar();

    const validationSchema = Yup.object({
        name: Yup.string()
            .min(2, "Name must be at least 2 characters")
            .required("This field is required"),
        email: Yup.string()
            .email("Invalid email format")
            .nullable(),
        phone: Yup.string()
            .matches(/^\+?[0-9\s\-]{5,}$/, "Please enter a valid phone number")
            .nullable(),
        telegram: Yup.string()
            .matches(/^@?[a-zA-Z0-9_-]+$/, "Please enter a valid Telegram username")
            .nullable(),
    }).test(
        'one-of-three-required',
        'Please fill in at least one of the following fields: email, phone number, or Telegram username',
        function (values) {
            return !!(values.email || values.phone || values.telegram);
        }
    );




    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            telegram: '',
            service: 'wwapps',
        },
        validationSchema,
        onSubmit: async (data) => {
            try {
                const response = await fetch('https://recojdnf.quest/send_message_to_tg/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-API-KEY': `${process.env.REACT_APP_API_KEY}`
                    },
                    body: JSON.stringify(data),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                enqueueSnackbar('Form was successfully sent', { variant: 'success' });
                formik.resetForm()

                return result;
            } catch (error) {
                enqueueSnackbar('Failed to send the form', { variant: 'error' });
            }
        },
    });

    return (
        <div className={s.formWrapper} ref={sectionRef}>
            <form className={s.formContainer} onSubmit={formik.handleSubmit}>
                <h5 className={s.header}>Ready to start cooperating? Fill out the form:</h5>
                <div className={s.inputsWrapper}>
                    <div className={s.inputsRow}>
                        <div className={s.inputWrapper}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                className={`${s.textInput} ${formik.touched.name && formik.errors.name ? s.error : ""}`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                            />
                            {formik.touched.name && formik.errors.name && (
                                <div className={s.errorMessage}>{formik.errors.name}</div>
                            )}
                        </div>
                        <div className={s.inputWrapper}>
                            <input
                                type="text"
                                name="email"
                                placeholder="Email"
                                className={`${s.textInput} ${formik.touched.email && formik.errors.email ? s.error : ""}`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            />
                            {formik.touched.email && formik.errors.email && (
                                <div className={s.errorMessage}>{formik.errors.email}</div>
                            )}
                        </div>
                    </div>
                    <div className={s.inputsRow}>
                        <div className={s.inputWrapper}>
                            <input
                                type="text"
                                name="phone"
                                placeholder="Phone"
                                className={`${s.textInput} ${formik.touched.phone && formik.errors.phone ? s.error : ""}`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone}
                            />
                            {formik.touched.phone && formik.errors.phone && (
                                <div className={s.errorMessage}>{formik.errors.phone}</div>
                            )}
                        </div>
                        <div className={s.inputWrapper}>
                            <input
                                type="text"
                                name="telegram"
                                placeholder="Telegram nickname"
                                className={`${s.textInput} ${formik.touched.telegram && formik.errors.telegram ? s.error : ""}`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.telegram}
                            />
                            {formik.touched.telegram && formik.errors.telegram && (
                                <div className={s.errorMessage}>{formik.errors.telegram}</div>
                            )}
                        </div>
                    </div>
                </div>
                {!formik.isValid && formik.submitCount > 0 && (
                    <div className={s.errorMessage}>
                        Please, fill in at least one of the following fields: email, phone number, or Telegram username.
                    </div>
                )}
                <button className={s.whiteBtn} type="submit">
                    Submit a request
                </button>
            </form>
        </div>
    );
};

export default Form;
