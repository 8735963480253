import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {SnackbarProvider} from 'notistack';

import Home from "./pages/Home/Home";
import './App.css';

function App() {
    return (
        <div className="App">
            <SnackbarProvider>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="*" element={<Navigate to="/"/>}/>
                </Routes>
            </SnackbarProvider>
        </div>
    );
}

export default App;
