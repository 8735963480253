import React from 'react';
import s from './advantages.module.scss'
import headphonesImg from '../../../assets/img/headphonesImg.png'
import phoneIconImg from '../../../assets/img/phoneIconImg.png'
import listIcon from '../../../assets/img/listIcon.png'

type Props = {
    sectionRef: React.RefObject<HTMLDivElement>
};

const Advantages: React.FC<Props> = ({sectionRef}) => {
    return <div className={s.advantagesWrapper} ref={sectionRef}>
        <div className={s.advantagesContainer}>
            <h3 className={s.header}>Advantages of cooperation:</h3>

            <ul className={s.advantagesList}>
                <li className={s.card}>
                    <div className={s.cardHeader}>
                        <img src={headphonesImg} alt="headphones"/>
                        <h5 className={s.headerText}>Fast and quality support 7 days a week: </h5>
                    </div>
                    <p className={s.description}>Our team is always available and ready to help at any time.</p>
                </li>
                <li className={s.card}>
                    <div className={s.cardHeader}>
                        <img src={phoneIconImg} alt="phone"/>
                        <h5 className={s.headerText}>Wide selection of applications: </h5>
                    </div>
                    <p className={s.description}>We offer iOS, Android, and PWA applications with unique designs for
                        popular slots and crash games.</p>
                </li>
                <li className={s.card}>
                    <div className={s.cardHeader}>
                        <img src={listIcon} alt="list"/>
                        <h5 className={s.headerText}>Individual conditions: </h5>
                    </div>
                    <p className={s.description}>We provide special terms for each client, allowing you to choose the
                        best options.</p>
                </li>
            </ul>
        </div>
    </div>;
};

export default Advantages;
