import React from 'react';
import s from './hero.module.scss'
import iphonesImg from '../../../assets/img/IPhones.png'
import logoImg from '../../../assets/img/logoImg.svg'

type Props = {
};

const Hero: React.FC<Props> = (props) => {
    return <div className={s.heroWrapper}>
        <div className={s.heroContainer}>
            <div className={s.textContent}>
                <img src={logoImg} alt=""/>
                <p>A reliable service for the development and rental of mobile applications! We offer innovative
                    solutions for IGaming that will help you increase your revenues.</p>
                <div className={s.buttonsWrapper}>
                    <a className={s.whiteBtn} href='https://t.me/Kate_head_sales_wwa' target='_blank'>Contact</a>
                    <a className={s.redBtn} href='https://maps.app.goo.gl/aA1KpzvSLyG8u67LA' target='_blank'>Location</a>
                </div>
            </div>
            <div className={s.iphonesImgContainer}>
                <img src={iphonesImg} alt="IPhones" className={s.iphonesImg}/>
            </div>
        </div>
    </div>;
};

export default Hero;
