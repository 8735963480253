import React from 'react';
import s from './footer.module.scss'
import {Link} from "react-router-dom";
import SvgSelector from "../../SvgSelector/SvgSelector";

type Props = {
    formRef: React.RefObject<HTMLDivElement>
};

const Footer: React.FC<Props> = ({formRef}) => {
    return <div className={s.footerWrapper}>
        <div className={s.footerContainer}>
            <Link to='/'>
                <SvgSelector id='logo'/>
            </Link>
            <ul className={s.copyrightContainer}>
                <div className={s.copyrightText}>
                    <p>© Copyright 2024</p>
                    <p>All rights reserved</p>
                </div>

                <div className={s.rightSideContainer}>
                    <a href="https://www.instagram.com/wildwildleads?igsh=ZHhkdzRheGRoYmFo" target='_blank'>
                        <SvgSelector id='inst-logo'/>
                    </a>
                    <button className={s.whiteBtn}
                            onClick={() => {
                                if (formRef.current) {
                                    const offsetTop = formRef.current.getBoundingClientRect().top + window.scrollY;
                                    window.scrollTo({
                                        top: offsetTop - 150,
                                        behavior: 'smooth',
                                    });
                                }
                            }}
                    >Submit
                    </button>
                </div>
            </ul>
        </div>
    </div>;
};

export default Footer;
