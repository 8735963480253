import React from 'react';

const SvgSelector = ({id}) => {

    switch (id) {

        case 'inst-logo': {
            return (
                <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M18.6666 5.45278H9.33331C6.75598 5.45278 4.66665 7.54212 4.66665 10.1194V19.4528C4.66665 22.0301 6.75598 24.1194 9.33331 24.1194H18.6666C21.2439 24.1194 23.3333 22.0301 23.3333 19.4528V10.1194C23.3333 7.54212 21.2439 5.45278 18.6666 5.45278ZM9.33331 3.11945C5.46732 3.11945 2.33331 6.25345 2.33331 10.1194V19.4528C2.33331 23.3188 5.46732 26.4528 9.33331 26.4528H18.6666C22.5326 26.4528 25.6666 23.3188 25.6666 19.4528V10.1194C25.6666 6.25345 22.5326 3.11945 18.6666 3.11945H9.33331Z"
                          fill="#FF0000"/>
                    <path
                        d="M19.8334 10.1195C20.4777 10.1195 21 9.59713 21 8.9528C21 8.30847 20.4777 7.78613 19.8334 7.78613C19.189 7.78613 18.6667 8.30847 18.6667 8.9528C18.6667 9.59713 19.189 10.1195 19.8334 10.1195Z"
                        fill="#FF0000"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M19.8334 14.7862C19.8334 18.0078 17.2217 20.6195 14 20.6195C10.7784 20.6195 8.16669 18.0078 8.16669 14.7862C8.16669 11.5645 10.7784 8.95282 14 8.95282C17.2217 8.95282 19.8334 11.5645 19.8334 14.7862ZM17.5 14.7862C17.5 16.7192 15.9331 18.2862 14 18.2862C12.067 18.2862 10.5 16.7192 10.5 14.7862C10.5 12.8531 12.067 11.2862 14 11.2862C15.9331 11.2862 17.5 12.8531 17.5 14.7862Z"
                          fill="#FF0000"/>
                </svg>
            )
        }

        case 'logo': {
            return (
                <svg width="119" height="41" viewBox="0 0 119 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M3.92573 40.2477L0 0.778191H7.21485L8.54111 27.6747L9.92042 0.778191H16.4456L12.8912 40.2477H3.92573Z"
                        fill="white"/>
                    <path
                        d="M19.3394 40.2477L15.4136 0.778191H22.6285L23.9547 27.6747L25.334 0.778191H31.8592L28.3049 40.2477H19.3394Z"
                        fill="white"/>
                    <path
                        d="M34.753 40.2477L30.8273 0.778191H38.0421L39.3684 27.6747L40.7477 0.778191H47.2729L43.7185 40.2477H34.753Z"
                        fill="white"/>
                    <path
                        d="M50.1666 40.2477L46.2409 0.778191H53.4557L54.782 27.6747L56.1613 0.778191H62.6865L59.1321 40.2477H54.6494H50.1666Z"
                        fill="white"/>
                    <path
                        d="M63.0343 18.2318L61.655 27.0912H66.96L65.5277 18.2318C64.838 13.8286 64.4667 11.07 64.3075 8.7888C64.0953 11.07 63.6709 13.9877 63.0343 18.2318ZM69.0821 40.2477L67.915 32.9798H60.7001L59.586 40.2477H54.8849L60.9483 0.778191H68.8168L75.8725 40.2477H69.0821Z"
                        fill="#FF0000"/>
                    <path
                        d="M86.2634 23.9082H83.0803V40.2477H76.396V0.778191H86.1572C88.9689 0.778191 90.9848 2.2636 90.9848 5.92408V18.6562C90.9848 22.2106 88.8628 23.9082 86.2634 23.9082ZM83.0803 5.87103V18.5501H83.5578C84.0883 18.5501 84.3535 18.2318 84.3535 17.5421V6.87899C84.3535 6.24238 84.2474 5.87103 83.5578 5.87103H83.0803Z"
                        fill="#FF0000"/>
                    <path
                        d="M101.805 23.9082H98.6224V40.2477H91.9381V0.778191H101.699C104.511 0.778191 106.527 2.2636 106.527 5.92408V18.6562C106.527 22.2106 104.405 23.9082 101.805 23.9082ZM98.6224 5.87103V18.5501H99.0999C99.6304 18.5501 99.8956 18.2318 99.8956 17.5421V6.87899C99.8956 6.24238 99.7895 5.87103 99.0999 5.87103H98.6224Z"
                        fill="#FF0000"/>
                    <path
                        d="M113.528 40.5129H112.361C108.966 40.5129 107.268 38.9214 107.268 35.7384V23.4307H112.361V35.3671C112.361 35.9506 112.52 36.2159 112.891 36.2159C113.263 36.2159 113.475 35.9506 113.475 35.3671V25.1283C113.475 24.0673 113.369 23.696 112.573 22.9002L109.761 20.1416C108.064 18.497 107.427 16.8525 107.427 14.3591V5.39358C107.427 2.42275 108.806 0.512939 112.573 0.512939H113.74C117.507 0.512939 118.621 2.42275 118.621 4.96917V15.5793H113.687V5.18137C113.687 4.75697 113.528 4.54477 113.157 4.54477C112.785 4.54477 112.626 4.75697 112.626 5.18137V14.1469C112.626 15.1548 112.785 15.4732 113.422 16.1628L116.18 18.9214C118.143 20.7782 118.833 22.1575 118.833 24.9161V35.1018C118.833 38.497 117.135 40.5129 113.528 40.5129Z"
                        fill="#FF0000"/>
                </svg>
            )
        }

        default:
            return null
    }
}

export default SvgSelector;