import React from 'react';
import s from './location.module.scss'
import locationImg from '../../../assets/img/locationImg.png'


type Props = {
    sectionRef: React.Ref<HTMLDivElement>;
};

const Location: React.FC<Props> = ({sectionRef}) => {
    return <div className={s.locationWrapper} ref={sectionRef}>
        <div className={s.locationContainer}>
            <h3 className={s.header}>Don't miss the opportunity to meet us from</h3>
            <div className={s.contentContainer}>
                <div className={s.textContent}>
                    <p className={s.textHeader}><span>November</span> 11 to 14 at </p>
                    <p className={s.textHeader}><span>Sigma</span> Europe in Malta!</p>
                    <p className={s.text}>This is a great opportunity for networking and discussing partnership prospects. Our team is
                        ready to share insights, ideas, and answer all your questions.</p>
                </div>
                <a href='https://maps.app.goo.gl/aA1KpzvSLyG8u67LA' target='_blank'>
                    <img src={locationImg} alt="location" className={s.locationImg}/>
                </a>
            </div>
        </div>
    </div>;
};

export default Location;
