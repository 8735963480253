import React, {RefObject} from 'react';
import s from './header.module.scss'
import SvgSelector from "../../SvgSelector/SvgSelector";
import {Link} from "react-router-dom";

type Props = {
    scrollAction: (ref: React.RefObject<HTMLDivElement>) => void;
    locationRef: React.RefObject<HTMLDivElement>;
    advantagesRef: React.RefObject<HTMLDivElement>;
};

const Header: React.FC<Props> = ({scrollAction, advantagesRef, locationRef}) => {
    return <div className={s.headerWrapper}>
        <div className={s.headerContainer}>

            <Link to='/'>
                <SvgSelector id='logo'/>
            </Link>
            <ul className={s.navigationList}>
                <li>
                    <button onClick={() => scrollAction(locationRef)}>Location</button>
                </li>
                <li>
                    <button onClick={() => scrollAction(advantagesRef)}>Advantages</button>
                </li>
            </ul>
        </div>
    </div>;
};

export default Header;
