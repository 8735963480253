import { ReportHandler, Metric } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: ReportHandler): void => {
  if (onPerfEntry) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      const wrapMetric = (metricFn: (callback: (metric: Metric) => void) => void) => {
        return (callback: ReportHandler) => {
          metricFn((metric) => {
            callback(metric);
          });
        };
      };

      wrapMetric(getCLS)(onPerfEntry);
      wrapMetric(getFID)(onPerfEntry);
      wrapMetric(getFCP)(onPerfEntry);
      wrapMetric(getLCP)(onPerfEntry);
      wrapMetric(getTTFB)(onPerfEntry);
    });
  }
};

export default reportWebVitals;
